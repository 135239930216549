<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
        >
          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Add New Role"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Designation"
                    label-for="designation"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Designation"
                      rules=""
                    >
                      <b-form-input
                        id="designation"
                        v-model="designation"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Choose Division"
                    label-for="chooseDivision"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Choose Division"
                      rules=""
                    >
                      <v-select
                        id="chooseDivision"
                        v-model="chooseDivision"
                        :options="['Program management','Event management','Network management','Startup management','Investment management','Resource management','Event management','Finance management', 'Media management']"
                        multiple
                        label="Choose Division"
                        placeholder="Choose Division"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Grant Admin Rights"
                    label-for="grantAdminRights"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Grant Admin Rights"
                      rules=""
                    >
                      <v-select
                        id="grantAdminRights"
                        v-model="grantAdminRights"
                        :options="['No','Chosen Division 1', 'Chosen Division 2']"
                        label="Grant Admin Rights"
                        placeholder="Grant Admin Rights"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { required } from '@core/utils/validations/validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
// import gql from 'graphql-tag'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    TabContent,
    // BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      designation: null,
      chooseDivision: null,
      grantAdminRights: null,
      required,
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
